 
import { type SerializeFrom } from '@remix-run/cloudflare'
import { useRouteLoaderData } from '@remix-run/react'
import { type loader as rootLoader } from '~/root'

function isUser(user: any): user is SerializeFrom<typeof rootLoader>['user'] {
	return user && typeof user === 'object' && typeof user.id === 'string'
}
// function isAdmin(user: any): user is SerializeFrom<typeof rootLoader>['user'] {
// 	return typeof user.is_admin === 'number'
// }
export function useOptionalUser() {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	if (!data || !isUser(data.user)) {
		return undefined
	}
	return data.user
}

export function useOptionalAdmin() {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	if (!data.user?.is_admin) {
		return undefined
	}
	if (!useOptionalUser) {
		return undefined
	}
	return data.user?.is_admin
}

export function useUser() {
	const maybeUser = useOptionalUser()
	if (!maybeUser) {
		throw new Error(
			'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
		)
	}
	return maybeUser
}
